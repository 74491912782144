import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ErrorBoundary.module.css'
import failedillustration from '../../../public/images/icons/failedillustration.svg'

const ErrorBoundaryDesign = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.errorBoundary}>
      <img
        src={failedillustration}
        alt="failed Illustration"
        className={styles.errorBoundaryImage}
      />
      <h3 className={styles.errorBoundaryTitle}>
        {t('misc.something_went_wrong')}
      </h3>
      <p className={styles.errorBoundaryText}>{t('misc.try_again')}</p>
      <div className={styles.errorBoundaryButtons}>
        <button type="button" className={styles.errorBoundaryButton}>
          <a
            href="mailto:support@ezrirx.com"
            className={styles.errorBoundaryButtonColor}
          >
            {t('misc.contact_us')}
          </a>
        </button>
        <button
          type="button"
          style={{ backgroundColor: '#6680A4' }}
          className={styles.errorBoundaryButton}
        >
          <a href="/" className={styles.errorBoundaryButtonColor}>
            {t('misc.go_home')}
          </a>
        </button>
      </div>
    </div>
  )
}

export default ErrorBoundaryDesign
