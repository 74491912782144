import {
  PHARMACY_ROUTE_PERMISSIONS,
  WHOLESALER_ROUTE_PERMISSIONS
} from './helpers/permissionHelper'
import { ROLE, isWholesaler } from './helpers/userHelper'
import loadable from '@loadable/component'

const Home = loadable(() =>
  import(/* webpackChunkName: "Home" */ './components/Home')
)
const MyOrders = loadable(() =>
  import(/* webpackChunkName: "MyOrders" */ './components/Orders/MyOrders')
)
const RpH2RpHordersReceived = loadable(() =>
  import(
    /* webpackChunkName: "RpH2RpHordersReceived" */ './components/Orders/RpH2RpHordersReceived'
  )
)
const OrderDetails = loadable(() =>
  import(
    /* webpackChunkName: "OrderDetails" */ './components/Orders/OrderDetails/OrderDetails'
  )
)
const ReceivedOrderDetails = loadable(() =>
  import('./components/Orders/OrderDetails/ReceivedOrderDetails')
)
const EditOrder = loadable(() =>
  import(
    /* webpackChunkName: "EditOrder" */ './components/Orders/OrderDetails/EditOrder/EditOrder'
  )
)
const ThankyouOrder = loadable(() =>
  import(
    /* webpackChunkName: "ThankyouOrder" */ './components/Orders/OrderDetails/ThankyouOrder'
  )
)
const PurchasedItems = loadable(() =>
  import(
    /* webpackChunkName: "PurchasedItems" */ './components/Orders/PurchasedItems'
  )
)
const Pedigrees = loadable(() =>
  import(
    /* webpackChunkName: "Pedigrees" */ './components/Orders/Pedigrees/Pedigrees'
  )
)
const PackingSlips = loadable(() =>
  import(
    /* webpackChunkName: "PackingSlips" */ './components/Orders/PackingSlips'
  )
)
const Invoices = loadable(() =>
  import(
    /* webpackChunkName: "Invoices" */ './components/Orders/Invoices/Invoices'
  )
)
const Invoice = loadable(() =>
  import(
    /* webpackChunkName: "Invoice" */ './components/Orders/Invoices/Invoice'
  )
)
const ApplyForWholesalers = loadable(() =>
  import(
    /* webpackChunkName: "ApplyForWholesalers" */ './components/WholesalersPage/ApplyForWholesalers'
  )
)
const PendingWholesalers = loadable(() =>
  import(
    /* webpackChunkName: "PendingWholesalers" */ './components/WholesalersPage/PendingWholesalers'
  )
)
const MyWholesalers = loadable(() =>
  import(
    /* webpackChunkName: "MyWholesalers" */ './components/WholesalersPage/MyWholesalers'
  )
)
const WholesalerDetails = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerDetails" */ './components/WholesalersPage/WholesalerDetails/WholesalerDetails'
  )
)
const Marketplace = loadable(() =>
  import(
    /* webpackChunkName: "Marketplace" */ './components/Marketplace/Marketplace'
  )
)
const Favorites = loadable(() =>
  import(
    /* webpackChunkName: "Favorites" */ './components/Marketplace/Favorites'
  )
)

const ProductDetails = loadable(() =>
  import(
    /* webpackChunkName: "ProductDetails" */ './components/Marketplace/ProductDetails/ProductDetails'
  )
)

const RpH2RpHinventory = loadable(() =>
  import(
    /* webpackChunkName: "RpH2RpHinventory" */ './components/Inventory/RpH2RpHinventory'
  )
)
const Deals = loadable(() =>
  import(/* webpackChunkName: "Deals" */ './components/Deals/Deals')
)
const ShortDates = loadable(() =>
  import(
    /* webpackChunkName: "ShortDates" */ './components/Marketplace/ShortDates'
  )
)
const OpenCart = loadable(() =>
  import(/* webpackChunkName: "OpenCart" */ './components/OpenCart/OpenCart')
)
const Offers = loadable(() =>
  import(
    /* webpackChunkName: "Offers" */ './components/Offers/OffersList/Offers'
  )
)
const OffersHistory = loadable(() =>
  import(
    /* webpackChunkName: "OffersHistory" */ './components/Offers/OffersList/OffersHistory'
  )
)
const OfferDetails = loadable(() =>
  import(
    /* webpackChunkName: "OfferDetails" */ './components/Offers/OfferDetails/OfferDetails'
  )
)
const Rewards = loadable(() =>
  import(/* webpackChunkName: "Rewards" */ './components/Rewards/Rewards')
)
const Messages = loadable(() =>
  import(/* webpackChunkName: "Messages" */ './components/Messages/Messages')
)
const ConversationView = loadable(() =>
  import(
    /* webpackChunkName: "ConversationView" */ './components/Messages/ConversationView'
  )
)
const Invite = loadable(() =>
  import(/* webpackChunkName: "Invite" */ './components/Invite/Invite')
)
const Account = loadable(() =>
  import(
    /* webpackChunkName: "Account" */ './components/Settings/Account/Account'
  )
)
const Security = loadable(() =>
  import(
    /* webpackChunkName: "Security" */ './components/Settings/Security/Security'
  )
)
const LicensesAndDocs = loadable(() =>
  import(
    /* webpackChunkName: "LicensesAndDocs" */ './components/Settings/LicensesAndDocuments/LicensesAndDocuments'
  )
)
const Payments = loadable(() =>
  import(
    /* webpackChunkName: "Payments" */ './components/Settings/Payments/Payments'
  )
)
const Preferences = loadable(() =>
  import(
    /* webpackChunkName: "Preferences" */ './components/Settings/Preferences/Preferences'
  )
)
const SwitchLocation = loadable(() =>
  import(
    /* webpackChunkName: "SwitchLocation" */ './components/Settings/SwitchLocation/SwitchLocation'
  )
)
const Scanner = loadable(() =>
  import(/* webpackChunkName: "Scanner" */ './components/Scanner/App')
)
const Cart = loadable(() =>
  import(/* webpackChunkName: "Cart" */ './components/ShoppingCart/Cart')
)
const CartDetailsFullPageWrapper = loadable(() =>
  import(
    /* webpackChunkName: "CartDetailsFullPageWrapper" */ './components/ShoppingCart/CartDetails/CartDetailsFullPageWrapper'
  )
)
const TermsOfService = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfService" */ './components/ShoppingCart/PreCheckoutPages/TermsOfService'
  )
)
const Laws = loadable(() =>
  import(
    /* webpackChunkName: "Laws" */ './components/ShoppingCart/PreCheckoutPages/Laws'
  )
)
const Fees = loadable(() =>
  import(
    /* webpackChunkName: "Fees" */ './components/ShoppingCart/PreCheckoutPages/Fees'
  )
)
const Faqs = loadable(() =>
  import(
    /* webpackChunkName: "Faqs" */ './components/ShoppingCart/PreCheckoutPages/Faqs'
  )
)
const WholesalerMyOrders = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerMyOrders" */ './components/Orders/WholesalerMyOrders'
  )
)
const WholesalerOrderDetails = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerOrderDetails" */ './components/Orders/OrderDetails/WholesalerOrderDetails'
  )
)
const WholesalerInventory = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerInventory" */ './components/Inventory/WholesalerInventory'
  )
)
const WholesalerInvoices = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerInvoices" */ './components/Orders/Invoices/WholesalerInvoices'
  )
)
const WholesalerInvoiceDetails = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerInvoiceDetails" */ './components/Orders/Invoices/WholesalerInvoice'
  )
)
const WholesalerOffers = loadable(() =>
  import(
    /* webpackChunkName: "WholesalerOffers" */ './components/Offers/OffersList/WholesalerOffers'
  )
)

export const authenticatedRoutes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/scan',
    component: Scanner,
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/orders',
    component: MyOrders,
    permission: PHARMACY_ROUTE_PERMISSIONS['/orders'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/orders/received_p2p',
    component: RpH2RpHordersReceived,
    permission: PHARMACY_ROUTE_PERMISSIONS['/orders/received_p2p'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/orders/received_p2p/:id',
    component: ReceivedOrderDetails,
    permission: PHARMACY_ROUTE_PERMISSIONS['/orders/received_p2p/:id'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/orders/:id',
    component: OrderDetails,
    permission: PHARMACY_ROUTE_PERMISSIONS['/orders/:id'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/orders/:id/edit',
    component: EditOrder,
    permission: PHARMACY_ROUTE_PERMISSIONS['/orders/:id/edit'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/orders/:id/thankyou',
    component: ThankyouOrder,
    permission: PHARMACY_ROUTE_PERMISSIONS['/orders/:id/thankyou'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/billing',
    component: Invoices,
    permission: PHARMACY_ROUTE_PERMISSIONS['/billing'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/billing/:id/invoice',
    component: Invoice,
    permission: PHARMACY_ROUTE_PERMISSIONS['/billing/:id/invoice'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/cart',
    component: Cart,
    permission: PHARMACY_ROUTE_PERMISSIONS['/cart'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/cart/:cartId/details',
    component: CartDetailsFullPageWrapper,
    permission: PHARMACY_ROUTE_PERMISSIONS['/cart/:cartId/details'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/cart/:cartId/tos',
    component: TermsOfService,
    permission: PHARMACY_ROUTE_PERMISSIONS['/cart/:cartId/tos'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/cart/:cartId/laws',
    component: Laws,
    permission: PHARMACY_ROUTE_PERMISSIONS['/cart/:cartId/laws'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/cart/:cartId/fees',
    component: Fees,
    permission: PHARMACY_ROUTE_PERMISSIONS['/cart/:cartId/fees'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/cart/:cartId/faqs',
    component: Faqs,
    permission: PHARMACY_ROUTE_PERMISSIONS['/cart/:cartId/faqs'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/pedigrees',
    component: Pedigrees,
    permission: PHARMACY_ROUTE_PERMISSIONS['/pedigrees'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/wholesalers/apply',
    component: ApplyForWholesalers,
    permission: PHARMACY_ROUTE_PERMISSIONS['/wholesalers/apply'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/wholesalers/pending',
    component: PendingWholesalers,
    permission: PHARMACY_ROUTE_PERMISSIONS['/wholesalers/pending'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/wholesalers/approved',
    component: MyWholesalers,
    permission: PHARMACY_ROUTE_PERMISSIONS['/wholesalers/approved'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/wholesalers/:id',
    component: WholesalerDetails,
    permission: PHARMACY_ROUTE_PERMISSIONS['/wholesalers/:id'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/settings/prefs',
    component: Preferences,
    permission: PHARMACY_ROUTE_PERMISSIONS['/settings/prefs'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/settings/switch_location',
    component: SwitchLocation,
    permission: PHARMACY_ROUTE_PERMISSIONS['/settings/switch_location'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/products',
    component: Marketplace,
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/products?p2p_only=p2p_only',
    component: Marketplace,
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/products/favorites',
    component: Favorites,
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/products/:id',
    component: ProductDetails,
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/pharmacy/inventory',
    component: RpH2RpHinventory,
    permission: PHARMACY_ROUTE_PERMISSIONS['/pharmacy/inventory'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/deals',
    component: Deals,
    permission: PHARMACY_ROUTE_PERMISSIONS['/deals'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/shortdates',
    component: ShortDates,
    permission: PHARMACY_ROUTE_PERMISSIONS['/shortdates'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/pharmacy/basket_with_deals',
    component: OpenCart,
    permission: PHARMACY_ROUTE_PERMISSIONS['/pharmacy/basket_with_deals'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/offers',
    component: Offers,
    permission: PHARMACY_ROUTE_PERMISSIONS['/offers'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/offers/history',
    component: OffersHistory,
    permission: PHARMACY_ROUTE_PERMISSIONS['/offers/history'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/offers/:id',
    component: OfferDetails,
    permission: PHARMACY_ROUTE_PERMISSIONS['/offers/:id'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/rewards',
    component: Rewards,
    permission: PHARMACY_ROUTE_PERMISSIONS['/rewards'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/messages',
    component: Messages,
    permission: (user) =>
      isWholesaler(user)
        ? WHOLESALER_ROUTE_PERMISSIONS['/messages']
        : PHARMACY_ROUTE_PERMISSIONS['/messages'],
    allowedRoles: [ROLE.pharmacy, ROLE.wholesaler]
  },
  {
    path: '/messages/:id',
    component: ConversationView,
    permission: (user) =>
      isWholesaler(user)
        ? WHOLESALER_ROUTE_PERMISSIONS['/messages/:id']
        : PHARMACY_ROUTE_PERMISSIONS['/messages/:id'],
    allowedRoles: [ROLE.pharmacy, ROLE.wholesaler]
  },
  {
    path: '/invite',
    component: Invite,
    permission: PHARMACY_ROUTE_PERMISSIONS['/invite'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/settings',
    component: Account,
    permission: (user) =>
      isWholesaler(user)
        ? WHOLESALER_ROUTE_PERMISSIONS['/settings']
        : PHARMACY_ROUTE_PERMISSIONS['/settings'],
    allowedRoles: [ROLE.pharmacy, ROLE.wholesaler]
  },
  {
    path: '/settings/security',
    component: Security,
    permission: (user) =>
      isWholesaler(user)
        ? WHOLESALER_ROUTE_PERMISSIONS['/settings/security']
        : PHARMACY_ROUTE_PERMISSIONS['/settings/security'],
    allowedRoles: [ROLE.pharmacy, ROLE.wholesaler]
  },
  {
    path: '/settings/licenses',
    component: LicensesAndDocs,
    permission: PHARMACY_ROUTE_PERMISSIONS['/settings/licenses'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/settings/payments',
    component: Payments,
    permission: PHARMACY_ROUTE_PERMISSIONS['/settings/payments'],
    allowedRoles: [ROLE.pharmacy]
  },
  {
    path: '/suppliers/orders',
    component: WholesalerMyOrders,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/orders'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/orders/:status',
    component: WholesalerMyOrders,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/orders/:status'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/orders/:id/view',
    component: WholesalerOrderDetails,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/orders/:id/view'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/inventory',
    component: WholesalerInventory,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/inventory'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/inventory/:status',
    component: WholesalerInventory,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/inventory/:status'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/invoices',
    component: WholesalerInvoices,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/invoices'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/invoices/:status',
    component: WholesalerInvoices,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/invoices/:status'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/invoices/:id/view',
    component: WholesalerInvoiceDetails,
    permission: 'My Orders.invoices'
  },
  {
    path: '/suppliers/offers',
    component: WholesalerOffers,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/offers'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/offers/:status',
    component: WholesalerOffers,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/offers/:status'],
    allowedRoles: [ROLE.wholesaler]
  },
  {
    path: '/suppliers/offers/:id/view',
    component: OfferDetails,
    permission: WHOLESALER_ROUTE_PERMISSIONS['/suppliers/offers/:id/view'],
    allowedRoles: [ROLE.wholesaler]
  }
]
