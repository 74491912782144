import React from 'react'
import * as Sentry from '@sentry/react'
import ErrorBoundaryDesign from './ErrorBoundaryDesign'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    if (process.env.RAZZLE_APP_ENV === 'production') {
      Sentry.captureException(error, { extra: info })
    }
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryDesign />
    }
    return this.props.children
  }
}
