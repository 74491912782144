export const installIntercomOnPageLoad = (loggedIn=false, userData={}) => {
  if (loggedIn) {
      window.intercomSettings = {
        app_id: process.env.RAZZLE_INTERCOM_APP_ID,
        name: userData.display_name,
        email: userData.email,
        user_id: userData.id,
        mobile_version: true
      };
    } else {
      window.intercomSettings = {
        app_id: process.env.RAZZLE_INTERCOM_APP_ID,
        mobile_version: true
      };
    }

  ;(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+process.env.RAZZLE_INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}
