import React from 'react'
import { Redirect } from 'react-router-dom'
import { encodeLocationPath } from '../../helpers/urlHelper'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import {
  checkIfWholesalerHasPermission,
  checkUserRole
} from '../../helpers/userHelper'
import { checkMultiplePermissions } from '../../helpers/permissionHelper'

export const AuthGuard = ({
  isAuthenticated,
  component: Component,
  routeProps,
  allowedRoles,
  pathPermission,
  user
}) => {
  // logout if permissions are not found for wholesaler user
  // which means that the user cookies is old data
  if (!checkIfWholesalerHasPermission(user)) {
    return <Redirect to="/logout" />
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login/',
          search: `?from=${encodeLocationPath(routeProps.location)}`
        }}
      />
    )
  }

  const isAllowedByRole = checkUserRole(user, allowedRoles)
  if (!isAllowedByRole) {
    return <Redirect to="/not-found" />
  }

  const permissions =
    typeof pathPermission === 'function' ? pathPermission(user) : pathPermission
  const isAllowedByPermission = checkMultiplePermissions(user, permissions)
  if (!isAllowedByPermission) {
    return <Redirect to="/forbidden" />
  }

  return (
    <ErrorBoundary>
      <Component {...routeProps} />
    </ErrorBoundary>
  )
}
