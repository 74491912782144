import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'

import * as prodDataFile from './optimizely/production_datafile.json'
import * as devDataFile from './optimizely/development_datafile.json'

import { refreshUserData as refreshUserDataAction } from './actions/user'

import loadable from '@loadable/component'

import ActionCableConsumerContext from './components/ActionCableConsumerContext'

import { installIntercomOnPageLoad } from './Intercom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.css'
import './styles/App.scss'
import { AuthGuard } from './components/AuthGuard'
import { authenticatedRoutes } from './routes'
import plausible from './helpers/plausibleHelper'

const Login = loadable(() => import('./components/Auth/Login'))
const Logout = loadable(() => import('./components/Auth/Logout'))
const Signup = loadable(() => import('./components/Auth/Signup/Signup'))
const ForgotPassword = loadable(() =>
  import('./components/Auth/ForgotPassword')
)
const OTP = loadable(() =>
  import('./components/Settings/Security/Auth/TwoFactor/Otp')
)
const ForbiddenPage = loadable(() =>
  import('./components/PageNotFound/ForbiddenPage')
)
const PageNotFound = loadable(() =>
  import('./components/PageNotFound/PageNotFound')
)
const BackToApp = loadable(() => import('./components/PageNotFound/BackToApp'))
const HellosignApp = loadable(() => import('./components/HellosignApp'))
const OldScanner = loadable(() => import('./components/Scanner/Scanner2'))
const UnAuthorized = loadable(() => import('./components/Auth/UnAuthorized'))

const SentryRoute = Sentry.withSentryRouting(Route)

const optimizely = createInstance({
  datafile:
    process.env.RAZZLE_APP_ENV === 'production'
      ? prodDataFile.default
      : devDataFile.default
})

const App = ({
  user,
  isAuthorized,
  consumer,
  location: appLocation,
  refreshUserData
}) => {
  const isAuthenticated = isAuthorized && Object.keys(user).length !== 0

  // Show user details to Sentry issue
  Sentry.setUser({
    email: user.email,
    id: user.dea_number,
    username: user.display_name
  })

  // Install and show Intercom
  useEffect(() => {
    // listen on every page changes
    installIntercomOnPageLoad(isAuthenticated, user)
    if (isAuthenticated && appLocation.pathname !== '/logout') {
      refreshUserData()
    }
    plausible.init()
    plausible.trackPageview()
  }, [appLocation])

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{ id: user && user.id && user.id.toString() }}
    >
      <ActionCableConsumerContext.Provider value={{ consumer, user }}>
        <Switch>
          <SentryRoute exact path="/login/" component={Login} />
          <SentryRoute exact path="/signup" component={Signup} />
          <SentryRoute
            exact
            path="/wholesalers/:supplierId/apply"
            component={HellosignApp}
          />
          <SentryRoute exact path="/logout" component={Logout} />
          <SentryRoute exact path="/old/scan" component={OldScanner} />
          <SentryRoute exact path="/login/otp" component={OTP} />
          <SentryRoute
            exact
            path="/password/recover"
            component={ForgotPassword}
          />
          {authenticatedRoutes.map(
            ({ path, component, allowedRoles, permission: pathPermission }) => (
              <SentryRoute
                key={path}
                exact
                path={path}
                render={(props) => (
                  <AuthGuard
                    isAuthenticated={isAuthenticated}
                    component={component}
                    routeProps={{ ...props, user, consumer }}
                    allowedRoles={allowedRoles}
                    pathPermission={pathPermission}
                    user={user}
                  />
                )}
              />
            )
          )}
          <SentryRoute exact path="/401" component={UnAuthorized} />
          <SentryRoute
            exact
            path="/wholesaler/desktopapp"
            component={BackToApp}
          />
          <SentryRoute exact path="/forbidden" component={ForbiddenPage} />
          <SentryRoute component={PageNotFound} />
        </Switch>
      </ActionCableConsumerContext.Provider>
    </OptimizelyProvider>
  )
}

const mapStateToProps = ({ user }) => ({
  user: user.userData,
  isAuthorized: user.isAuthorized
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      refreshUserData: refreshUserDataAction
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(App)
